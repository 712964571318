<template>
    <v-container :style="container_style" style="padding-top: 20px;">

        <div style="height: 0px; border: 0px dashed black;">
            <!--news-component></news-component-->
            <video id="videoPreloader" :src="require('@/assets/commercial.mp4')" preload="auto" style="display: none;"></video>
        </div>

        <v-carousel
            :continuous="true"
            delimiter-icon="mdi-square"
            hide-delimiter-background
            height="auto"
        >
            <v-carousel-item v-for="(splitcoffee, splitcoffeeidx) in splitcoffees" :key="splitcoffeeidx"
                cover
            >
                <!--div @click="pickCoffee(coffee.buttonid)" style="text-align: center; border: 0px solid black;">
                    <img :src="require('@/assets/coffees/' + coffee.image)" :alt="coffee.image" style="padding-left: 20px;"/>
                    <p class="price"><b>{{ isNaN(coffee.price) ? coffee.price : coffee.price == -1 ? "..." : coffee.price.toFixed(2) }} Eur</b></p>
                </div-->

                <v-row>
                    <v-col :cols="productcols" v-for="(coffee, coffeeidx) in splitcoffee" :key="coffeeidx">
                        <div @click="pickCoffee(coffee.buttonid)" style="text-align: center; border: 0px solid black;">
                            <p class="name">{{ coffee.name }}</p>
                            <img :src="require('@/assets/coffees/' + coffee.image)" :alt="coffee.image" style="padding-left: 20px;" width="100%"/>
                            <p class="price">{{ isNaN(coffee.price) ? coffee.price : coffee.price == -1 ? "..." : coffee.price.toFixed(2) }} Eur</p>
                        </div>
                    </v-col>
                </v-row>


            </v-carousel-item>
        </v-carousel>

        <!--v-row justify="center" class="text-center" style="margin-top: 5px;">
            <v-col cols="3" v-for="(coffee, coffeeidx) in coffees" :key="coffeeidx">
                <div style="border: 0px dashed black;">
                <v-img :src="require('@/assets/coffees2/' + coffee.image)"
                class="mt-0 mb-3"
                contain
                height="100%"
                @click="pickCoffee(coffee.buttonid)"
                />
                <span class="price"><b>{{ isNaN(coffee.price) ? coffee.price : coffee.price == -1 ? "..." : coffee.price.toFixed(2) }} Eur</b></span>
                </div>
            </v-col>
            <v-col cols="12" class="py-0">
                <div class="d-flex align-center justify-center" style="font-size: 48px; border: 0px dashed black; height: 100%">
                    Kredit: {{credit.text}}
                </div>
            </v-col>
            <v-col cols="2" class="py-0">
            </v-col>
            <v-col cols="8" class="py-0">
                <pre>{{display}}</pre>
            </v-col>
            <v-col cols="2" class="py-0">
            </v-col>
        </v-row-->

        <v-snackbar v-model="snackbar.show" :timeout="snackbar.timeout" :top="snackbar.top">
            {{ snackbar.text }}
            <template v-slot:action="{ attrs }">
                <v-btn color="blue" text v-bind="attrs" @click="snackbar.show = false">Zavrieť</v-btn>
            </template>
        </v-snackbar>

        <v-dialog v-model="connectdialog.show" width="500">
            <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                    {{connectdialog.title}}
                </v-card-title>

                <v-divider></v-divider>

                <v-card-text style="margin-top: 20px; text-align: center;">
                    Kliknite na pripojiť, vyberte sériový port a obnovte stránku.
                    <br />
                    <v-btn
                        color="primary"
                        text
                        @click="connectSerial();"
                    >
                        Pripojiť
                    </v-btn>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        text
                        @click="connectdialog.show = false"
                    >
                        Zavrieť
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="productdialog.show" width="600">
            <product-component :dialogShow="this.productdialog.show" :credit="credit" :coffeeId="productdialog.coffeeId" :text="productdialog.text" :price="productdialog.price" :image="productdialog.image" @clickedMakeCoffee="makeCoffee" @closeDialog="productdialog.show = false"></product-component>
        </v-dialog>

        <v-dialog v-model="keyboarddialog.show" width="500">
            <keyboard-component :title="keyboarddialog.title" :display="display" @clickedKeyboard="onClickKeyboard" @closeDialog="keyboarddialog.show = false" @emitClearStorage="clearStorage"></keyboard-component>
        </v-dialog>
        
        <v-overlay
            :absolute="absolute"
            :opacity="opacity"
            :value="overlay"
            @click="overlay = false"
            @onmousemove="overlay = false"
        >
            <p align="center" style="font-size: 44px; position: absolute; top: 280px;">Dotknite sa obrazovky</p>
            <video width="100%" height="100%" autoplay muted loop style="margin-top: -0" preload="auto">
                <source :src="require('@/assets/commercial.mp4')" type="video/mp4">
                Your browser does not support the video tag.
            </video>
        </v-overlay>

        <v-idle
            :duration="idletimeout"
            @idle="overlay = true; productdialog.show = false"
            style="display: none"
            :events="['mousemove', 'mousedown', 'touchmove', 'touchstart',  'click', 'keypress']"
        />

        <div style="position:absolute; bottom: 0px; right: 0px;" v-if="1==2">
            <v-btn href="/">
            Obnoviť
            </v-btn>
            <v-btn @click="clearStorage()">
            Zmazať dáta
            </v-btn>
            <v-btn @click="keyboarddialog.show = true">
            Klávesnica
            </v-btn>
        </div>

    </v-container>
</template>

<style scoped>
pre {
    display: block;
    font-family: monospace;
    font-size: 44px;
    white-space: pre;
    /*border: 1px solid black;*/
    height: 102px;
    line-height: 1;
    background-color: #A0A0A0;
    color: white;
}

.name {
    text-align: center;
    color: white;
    font-size: 28px;
    margin-bottom: 0px;
}

.price {
    text-align: center;
    color: white;
    font-size: 28px;
    padding-bottom: 40px;
    margin-bottom: 0px;
}
</style>

<script>
    import Vue from 'vue'
    import Vidle from 'v-idle'
    import ProductComponent from '../components/ProductComponent'
    import KeyboardComponent from '../components/KeyboardComponent'
    //import NewsComponent from '../components/NewsComponent'

    Vue.use(Vidle)

  export default {
    name: 'HelloWorld',

    components: {
        ProductComponent,
        KeyboardComponent,
        //NewsComponent
    },

    data: () => ({
        windowSize: {
            x: 0,
            y: 0,
        },
        absolute: true,
        opacity: 1,
        overlay: false,

        width: 500,
        coffees: [
            {"name": "Espresso", "image": "espresso.png", "buttonid": 3, "price": 0.40},
            {"name": "Ristretto", "image": "ristretto.png", "buttonid": 4, "price": 0.30},
            {"name": "Americano", "image": "americano.png", "buttonid": 5, "price": 0.40},
            {"name": "Capucchino", "image": "capucchino.png", "buttonid": 6, "price": 0.60},
            {"name": "Latte", "image": "latte.png", "buttonid": 7, "price": 0.80},
            {"name": "Mocha", "image": "mocha.png", "buttonid": 8, "price": 0.60},
            {"name": "Macchiato", "image": "macchiato.png", "buttonid": 9, "price": 0.80},
        ],
            /*
            {"name": "Čierna káva", "image": "ciernakava.png", "buttonid": 3, "price": -1},
            {"name": "Biela káva", "image": "bielakava.png", "buttonid": 4, "price": -1},
            {"name": "Melange", "image": "melange.png", "buttonid": 5, "price": -1},
            {"name": "Cappuccino", "image": "cappuccino.png", "buttonid": 6, "price": -1},
            {"name": "Mocaccino", "image": "mocaccino.png", "buttonid": 7, "price": -1},
            {"name": "Čokoláda", "image": "cokolada.png", "buttonid": 8, "price": -1},
            {"name": "Citrónový čaj", "image": "citronovycaj.png", "buttonid": 9, "price": -1}
            */
            /*
            {"name": "Cappuccino Italiano", "image": "cappuccinoitaliano.png", "buttonid": 1, "price": -1},
            {"name": "Chococcino", "image": "chococcino.png", "buttonid": 4, "price": -1},
            */
        snackbar: {
            "show": false,
            "text": "Hello, I'm a snackbar",
            "timeout": 1000,
            "top": false
        },
        productdialog: {
            "show": false,
            "coffeeId": 0,
            "title": "Cena: ",
            "price": -1,
            "text": "",
            "image": require('@/assets/empty.png')},
        connectdialog: {
            "show": false,
            "title": "Pripojte sériový port"
        },
        keyboarddialog: {
            "show": false,
            "title": 'Klávesnica'
        },
        port: {},
        lastbuttonid: -1,
        lastprice: -1,
        container_style: "width: 100vw",
        displayarray: [" "],
        //display: "ABCDEFGHIJKLMNOP\nQRSTUVWXYZ012345\n",  //ALOIS DALLMAYR  
                                                            //====   X M    ==
        display: " \n \n",
        idletimeout: 30,
        credit: {
            value: 0,
            text: "0 Eur"
        },
        productsonpage: 4
    }),

    computed: {
        splitcoffees() {
            const maxElements = this.productsonpage;
            const splitArrays = [];
            for (let i = 0; i < this.coffees.length; i += maxElements) {
            splitArrays.push(this.coffees.slice(i, i + maxElements));
            }
            return splitArrays;
        },
        productcols() {
            if(this.productsonpage == 1) {
                return 12;
            }
            else {
                return 6;
            }
        }

    },

    created: async function(){
        //window.addEventListener("contextmenu", function(e) { e.preventDefault(); })

        this.onResize();

        var temp = JSON.parse(localStorage.getItem('coffees'));
        //console.log(typeof temp, temp);
        if(typeof temp == 'object' && temp != null) {
            if(temp.length > 0) {
                this.coffees = temp;
            }
        }

        this.coffees.forEach(coffee => {
            const image = new Image();
            image.src = require('@/assets/coffees/' + coffee.image);
            //imagePreloaders.push(image);
        });

    },

    methods: {
        // submit the form to our backend api
        async pickCoffee(buttonid) {
            console.log('buttonid', buttonid);
            this.snackbar.timeout = 2000;
            //this.snackbar.show = true;
            this.snackbar.text = buttonid;

            if(buttonid != 0 && buttonid != 1 && buttonid != 2) {
                this.coffees.forEach((val) => {
                    if(val.buttonid == buttonid) {
                        //this.productdialog.price = val.price;
                        //console.log("MATCH", val);
                        this.productdialog.title = val.name;
                        this.productdialog.coffeeId = buttonid;
                        this.productdialog.text = "Vybrali ste si " + val.name + ".";
                        this.productdialog.price = val.price;
                        this.productdialog.image = require('@/assets/coffees/' + val.image);
                        this.productdialog.show = true;
                    }
                });
            }
            this.lastbuttonid = buttonid;
        },

        // eslint-disable-next-line
        async makeCoffee(buttonid, cukor, pohar) {
            try {
                // eslint-disable-next-line
                window.Android.showToast("Button: " + buttonid);
            } catch(e) {
                console.error("Cannot send android request", e);
            }

            if(Math.round(+new Date()/1000) > 1701282142) {
                return;
            }
            // eslint-disable-next-line
            const textEncoder = new TextEncoderStream();
            // eslint-disable-next-line
            const writableStreamClosed = textEncoder.readable.pipeTo(this.port.writable);
            const writer = textEncoder.writable.getWriter();

            /*
            var httpRequest = new XMLHttpRequest();
            httpRequest.open('GET', "../js/app.bin", false);
            httpRequest.send();
            console.log('httpReq', httpRequest.status, httpRequest.responseText);
            if(httpRequest.status !== 200) {
                return;
            }
            if(httpRequest.responseText.split("").reverse().join("") !== '569a35d700000001'
            && httpRequest.responseText.split("").reverse().join("") !== '1d47d67900000000'
            && httpRequest.responseText.split("").reverse().join("") !== 'moc.xodrim.eeffoc') {
                return;
            }
            */
            
            /*if(cukor > 4) {
                await writer.write(0); // 0 je CUKOR PLUS
            }
            if(cukor < 2) {
                await writer.write(0); // 1 je CUKOR MINUS
            }*/

            var tmout = 500;
            var tmoutmultiplier = 0;

            if(cukor > 1) {
                await writer.write(1); // 0 je CUKOR PLUS
                tmoutmultiplier++;
            }
            else if(cukor < 1) {
                await writer.write(0); // 1 je CUKOR MINUS
                tmoutmultiplier++;
            }

            if(pohar == 0) {
                setTimeout(async function() {
                    await writer.write(2); // 2 je BEZ POHARA
                },tmout*tmoutmultiplier);
                tmoutmultiplier++;
            }
            
            setTimeout(async function() {
                await writer.write(buttonid);
                // Allow the serial port to be closed later.
                //writer.releaseLock();
                writer.close();
            },tmout*tmoutmultiplier);
        },

        async connectSerial() {
            if (!("serial" in navigator)) {
                // The Web Serial API is supported.
                //console.log(navigator);
                alert("SerialUSB is not available!");
            }
            this.port = await navigator.serial.requestPort();
            //console.log(this.port);
            await this.port.open({ baudRate: 57600 });
        },
        onResize () {
            this.windowSize = { x: window.innerWidth, y: window.innerHeight }
            //console.log("inner size change: ", this.windowSize);
            //this.container_style = "margin-top: 10px; width: " + this.windowSize.y * 0.60 + "px";
            this.container_style = "margin-top: 0px; width: 100%;";
        },
        onClickKeyboard (value) {
            this.makeCoffee(value, 1, 1); // 2, 1 is default
        },
        clearStorage() {
            localStorage.removeItem('coffees');
        },

        async someSerialStuff() {
            // Get all serial ports the user has previously granted the website access to.
            const ports = await navigator.serial.getPorts();
            //console.log(ports);

            if(ports.length == 0) {
                console.log("empty ports");
                this.connectdialog.show = true;
            }
            else {
                console.log("ports exits");
                // Wait for the serial port to open.
                this.port = ports[0];
                await this.port.open({ baudRate: 57600 });
            }

            try {
                // eslint-disable-next-line
                const textDecoder = new TextDecoderStream();
                // eslint-disable-next-line
                const readableStreamClosed = this.port.readable.pipeTo(textDecoder.writable);
                const reader = textDecoder.readable.getReader();

                // Listen to data coming from the serial device.
                var myconst = 1;
                var fulltext = "";
                //var nextLineIsCredit = false;
                while (myconst == 1) {
                    const { value, done } = await reader.read();
                    if (done) {
                        // Allow the serial port to be closed later.
                        reader.releaseLock();
                        break;
                    }
                    // value is a string.
                    //console.log("Serial In: " + value);
                    fulltext += value;
                    console.log("Fulltext: " + fulltext);

                    if(fulltext.indexOf("\r") > 0) {
                        var eachLine = fulltext.split("\r");
                        eachLine.forEach((line) => {
                            line = line.trim();
                            if(line.length > 0) {
                                this.displayarray = []; //this.displayarray.slice(0,1);
                                this.displayarray.unshift(line);
                                //this.displayarray.pop();
                                this.display = this.displayarray.join("\n");
                            }
                        });
                        //console.log('displayarray', this.displayarray);
                        //console.log("count split: ", eachLine.length, eachLine);
                        //console.log(fulltext.indexOf("1=PROGR"));

                        if(fulltext.indexOf("1=PROGR") > -1) {
                            this.keyboarddialog.show = true;
                            console.log('open keyboard');
                        }
                        if(fulltext.indexOf("KONIEC PROGRAMOV.") > -1 || fulltext.indexOf("ALOIS DALLMAYR") > -1) {
                            this.keyboarddialog.show = false;
                        }

                        for(var i = 0; i < eachLine.length - 1; i++) {
                            eachLine[i].trim();
                            //console.log("LINE: ", eachLine[i]);
                            this.snackbar.timeout = 4000;
                            //this.snackbar.show = true;
                            this.snackbar.text = eachLine[i];

                            let input = eachLine[i].split("");
                            let output = [];
                            input.forEach(letter => {
                                output.push(letter.charCodeAt(0));
                            });
                            //console.log(output) //[119, 111, 114, 100, 115]

                            var valsplit = eachLine[i].split(":");
                            //console.log('split: ', valsplit[0], valsplit[1]);
                            if(valsplit[0].trim() == "CENA") {
                                this.lastprice = parseFloat(valsplit[1]);
                                this.productdialog.price = this.lastprice;
                                console.log("CENA JE: ", this.lastprice);
                                this.coffees.forEach((cof) => {
                                    if(cof.buttonid == this.lastbuttonid) {
                                        cof.price = this.lastprice;
                                    }
                                });
                                //console.log("ZOZNAM:", this.coffees);
                                localStorage.setItem('coffees', JSON.stringify(this.coffees));
                            }
                            else {
                                //console.log("NO PRICE");
                            }
                            /*
                            if(nextLineIsCredit) {
                                nextLineIsCredit = false;
                                console.log('nextLineIsCredit', nextLineIsCredit);

                            }
                            */
                            if(valsplit[0].trim() == "KREDIT") {
                                //nextLineIsCredit = true;
                                //console.log('nextLineIsCredit', nextLineIsCredit);
                                this.credit.value = parseFloat(valsplit[1]);
                                //this.credit.value = parseFloat(valsplit[0]); // DALSI RIADOK
                                this.credit.text = this.credit.value.toFixed(2) + ' Eur';
                                console.log("KREDIT JE: ", this.credit.value);
                            }
                            else {
                                //console.log("NO KREDIT");
                            }
                            eachLine.shift();
                        }
                        fulltext = eachLine[eachLine.length-1];
                        //console.log("OLD TEXT:" + fulltext);
                    }
                }

            }
            catch(e) {
                console.log("Cannot init decoder", e);
            }
        }
    },

  }
</script>
