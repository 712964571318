<template>
    <v-card>
        <!--v-card-title class="text-h5 grey lighten-2">
        </v-card-title-->

        <v-divider></v-divider>

        <v-card-text class="my-2 py-0" style="font-size: 32px; text-align: center;">
            <!--{{text}}-->
            <v-img :src="image"
            width="50%"
            height="100%"
            class="mx-auto my-2"
            />
            {{ isNaN(price) ? price : price == -1 ? "..." : price.toFixed(2) }} Eur<br /><br />
            <!--Kredit: {{credit.text}}-->
        </v-card-text>

        <v-divider></v-divider>

        <v-card-text class="my-1 py-0" style="font-size: 24px; text-align: center;">
            <p class="mt-2 mb-2">Cukor:</p>
            <v-btn-toggle v-model="cukorValue" variant="outlined" divided>
                <v-btn tile>žiadny</v-btn>
                <v-btn tile>stredne</v-btn>
                <v-btn tile>viac</v-btn>
            </v-btn-toggle>
            <!--v-slider
                v-model="cukorValue"
                step="1"
                min="0"
                max="2"
                ticks="always"
                style="width: 90%; margin-left: 5%"
                label="Cukor:"
                :tick-labels="['žiadny', 'stredne', 'viac']"
            ></v-slider-->
        </v-card-text>

        <v-divider></v-divider>

        <v-card-text class="my-1 py-0" style="font-size: 24px; text-align: center;">
            <p class="mt-2 mb-2">Pohár:</p>
            <v-btn-toggle v-model="poharValue" variant="outlined" divided>
                <v-btn tile>nie</v-btn>
                <v-btn tile>áno</v-btn>
            </v-btn-toggle>
            <!--v-slider
                v-model="poharValue"
                step="1"
                min="0"
                max="1"
                ticks="always"
                style="width: 60%; margin-left: 15%"
                label="Pohár:"
                :tick-labels="['nie','áno']"
            ></v-slider-->
        </v-card-text>

        <v-divider></v-divider>

        <v-card-text style="margin-top: 20px; font-size: 25px; text-align: center;">
        <v-row class="mb-6" style="padding-top: 30px;">
            <v-col cols="6">
                <v-btn
                    large
                    color="error"
                    tile
                    @click="$emit('closeDialog')"
                >
                    <v-icon x-large left>mdi-close-circle-outline</v-icon>
                    <!--font-awesome-icon icon="times-circle" size="3x" /-->
                    &nbsp;
                    Zrušiť
                </v-btn>
            </v-col>
            <v-col cols="6">
                <v-btn
                    large
                    justify="end"
                    color="success"
                    tile
                    @click="$emit('clickedMakeCoffee', coffeeId, cukorValue, poharValue); $emit('closeDialog');"
                    v-if="sufficientCredit"
                >
                    <v-icon x-large left>mdi-check-circle-outline</v-icon>
                    <!--font-awesome-icon icon="check-circle" size="3x" /-->
                    &nbsp;
                    Vybrať
            </v-btn>
            </v-col>

            
            


        </v-row>
        </v-card-text>

        <v-divider></v-divider>


        <!--v-card-actions class="text-h5 grey lighten-2" >
        </v-card-actions-->
    </v-card>
</template>

<style scoped>

.v-slider__track-container {
   height: 20px;
}
.v-slider__track {
   height: 20px;
}

</style>

<script>
  export default {
    name: 'ProductComponent',
    props: ['text', 'price', 'image', 'coffeeId', 'credit', 'dialogShow'],
    data: () => ({
        cukorValue: 1,
        poharValue: 1,
        show: false,
        prodDialogTimeout: null,
        timeoutDuration: 40000
    }),
    computed: {
        sufficientCredit() {
            return true; //this.credit.value >= this.price ? true : false;
        },
        compCredit() {
            return this.credit.value;
        }
    },
    watch: {
        dialogShow(newVal, oldVal) {
            //console.log('timer', this.prodDialogTimeout);
            console.log('dialogShow', newVal, oldVal);
            if(newVal == true && oldVal == false) {
                this.cukorValue = 1;
                this.poharValue = 1;

                // eslint-disable-next-line
                this.prodDialogTimeout = setTimeout(() => {this.$emit('closeDialog')}, this.timeoutDuration);
                console.log('settimeout', this.prodDialogTimeout);
            }
            else {
                clearTimeout(this.prodDialogTimeout);
                console.log('cleartimeout', this.prodDialogTimeout);
            }
        },
        // eslint-disable-next-line
        compCredit(newVal, oldVal) {
            console.log('credit', newVal, oldVal);
            clearTimeout(this.prodDialogTimeout);
            this.prodDialogTimeout = setTimeout(() => {this.$emit('closeDialog')}, this.timeoutDuration);
        },
        // eslint-disable-next-line
        cukorValue(newVal, oldVal) {
            clearTimeout(this.prodDialogTimeout);
            this.prodDialogTimeout = setTimeout(() => {this.$emit('closeDialog')}, this.timeoutDuration);
            console.log('cukorValue', newVal, oldVal);
            /*
            if(newVal == 2) {
                this.$emit('clickedMakeCoffee', 1);
            }
            else if(newVal == 1 && oldVal == 0) {
                this.$emit('clickedMakeCoffee', 0);
            }
            else if(newVal == 1 && oldVal == 2) {
                this.$emit('clickedMakeCoffee', 1);
            }
            else if (newVal == 0) {
                this.$emit('clickedMakeCoffee', 0);
            }
            */
        },
        // eslint-disable-next-line
        poharValue(newVal, oldVal) {
            clearTimeout(this.prodDialogTimeout);
            this.prodDialogTimeout = setTimeout(() => {this.$emit('closeDialog')}, this.timeoutDuration);
            //console.log('poharValue', newVal, oldVal);
            /*
            if(newVal != oldVal) {
                this.$emit('clickedMakeCoffee', 2);
            }
            */
        }
    },
    methods: {
    }
  }
</script>