<template>
    <v-card>
        <!--v-card-title class="text-h5 grey lighten-2">
            {{title}}
        </v-card-title-->

        <!--v-divider></v-divider-->

        <v-card-text style="margin-top: 10px; text-align: center;">

            <v-row>
            <v-col cols="12" align="center">
                <pre height="44">{{display}}</pre>
            </v-col>
            </v-row>

            <v-row v-for="(button, buttonidx) in buttonlist" :key="buttonidx" class="text-center">
            <v-col cols="12" class="py-1">
                    <v-btn @click="$emit('clickedKeyboard', button)" v-html="buttonname[buttonidx]" block rounded outlined small color="primary">
                    </v-btn>
            </v-col>
            </v-row>

        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="py-0">
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                text
                @click="$emit('emitClearStorage')"
            >
                Zmazať dáta
            </v-btn>
            <!--v-btn
                color="primary"
                text
                @click="$emit('closeDialog')"
            >
                Zavrieť
            </v-btn-->
        </v-card-actions>
    </v-card>
</template>

<script>
  export default {
    name: 'KeyboardComponent',
    props: ['title', 'display'],
    data: () => ({
        //title: "Klávesnica",
        buttonlist: [0,1,2,3,4,5,6,7,8,9],
        buttonname: ['&uarr;&uarr; 1', '&uarr; 2', '&darr; 3', '+ 4', '- 5', '&darr;&darr; 6', '7', '8', '9', '10'],
        show: false
    }),
    methods: {
     onClickButton () {
         this.$emit('clickedKeyboard', 'someValue')
     }
        
    }
  }
</script>