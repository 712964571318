import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,[_c(VCardText,{staticStyle:{"margin-top":"10px","text-align":"center"}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12","align":"center"}},[_c('pre',{attrs:{"height":"44"}},[_vm._v(_vm._s(_vm.display))])])],1),_vm._l((_vm.buttonlist),function(button,buttonidx){return _c(VRow,{key:buttonidx,staticClass:"text-center"},[_c(VCol,{staticClass:"py-1",attrs:{"cols":"12"}},[_c(VBtn,{attrs:{"block":"","rounded":"","outlined":"","small":"","color":"primary"},domProps:{"innerHTML":_vm._s(_vm.buttonname[buttonidx])},on:{"click":function($event){return _vm.$emit('clickedKeyboard', button)}}})],1)],1)})],2),_c(VDivider),_c(VCardActions,{staticClass:"py-0"},[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.$emit('emitClearStorage')}}},[_vm._v(" Zmazať dáta ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }