<template>
  <coffee-list />
</template>

<script>
  import CoffeeList from '../components/CoffeeList'

  export default {
    name: 'HomeView',

    components: {
      CoffeeList,
    },
  }
</script>
